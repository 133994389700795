<!-- 通用文章页 -->
<template>
  <div class="details centebox">
    <span>
      <div class="heasd">
        <span @click="requestList" style="cursor: pointer">
          <i class="el-icon-arrow-left" style="margin: 0 8px"></i> 通知公告
        </span>
      </div>
    </span>
    <h1>
      {{ contentData.title }}
      <p class="datasource">
        <span>发布日期：{{ clobDO.createTime | formatDate }}</span>
        <span>来源：人才办</span>
      </p>
    </h1>
    <div class="pages_content" v-html="clobDO.content"></div>

    <div class="filess">
      <ul>
        <li v-for="(item, index) in files" :key="index">
          <span @click="downloaded(item.path)"
            ><i class="el-icon-folder-opened"></i> {{ item.name }}</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import config from "@/utils/config";

export default {
  data() {
    return {
      createLoading: false,
      contentData: {},
      clobDO: "",
      Columnname: "",
      files: [],
      fileurl: config.fileurl,
    };
  },

  //用于数据初始化
  created() {
    this.Getcontent(); //获取详情
  },

  //用于存放所有的事件方法集合
  methods: {
    requestList() {
      this.$router.push({ path: "/declare" });
    },
    Getcontent() {
      var self = this;
      this.$axios
        .get("/article", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then(function(res) {
          self.files = res.data.data.files;
          self.contentData = res.data.data;
          self.clobDO = res.data.data.clobDO;
          self.getColumn(res.data.data.columnId);
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    getColumn(Id) {
      var self = this;
      this.$axios
        .get("/column", {
          params: {
            id: Id,
          },
        })
        .then(function(res) {
          self.Columnname = res.data.data.name;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    downloaded(path) {
      window.location.href = this.fileurl + path;
    },
  },
};
</script>

<style lang="less" scoped>
.filess {
  padding: 24px 0;

  li {
    text-align: left;
    color: #0c94ea;
    span {
      cursor: pointer;
    }
  }
}
.details {
  background: #ffffff;
  max-width: 1200px;

  h1 {
    /*margin: 0 auto;*/
    /*width: 1140px;*/
    padding: 16px 0 0 0;
    /*line-height: 45px;*/
    border-bottom: 1px #ddd solid;

    p {
      padding: 0;
      margin: 0;
      height: 65px;
      line-height: 70px;

      span {
        font-size: 16px;
        font-weight: 500;
        padding: 0 15px;
      }
    }
  }

  .datasource span {
    padding: 0 10px;
  }

  .pages_content {
    padding: 20px;
    text-align: left;
    line-height: 36px;
    text-indent: 2em;
    word-wrap: break-word;
    word-break: normal;
    min-height: 400px;
  }
}

.heasd {
  height: 40px;
  border-bottom: 1px #ddd solid;
  text-align: left;
  line-height: 40px;
  padding: 0 0 0 15px;
  color: #2380d7;
  font-weight: bold;
}
</style>
